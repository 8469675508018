import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link, Router, Redirect, navigate, useLocation } from "@reach/router";
import { format, parseISO } from "date-fns";
import { FiSearch, FiUser } from "react-icons/fi";
import ShippingStore from "../../stores/ShippingStore";
import useModal from "../../hooks/useModal";
import useQueryParams from "../../hooks/useQueryParams";
import { Spinner, Select, Input } from "../-common";
import { ChangeShipByDateModal as ChangeShipByDateModalBody } from "../-common";
import UpcomingShipmentSchedule from "./UpcomingShipmentSchedule";
import ShipmentOverview from "./ShipmentOverview";
import "./Shipping.scss";

const sortOptions = ["Requested Ship Date", "Class Start Date", "Students Who Have Waited Longest"];

const sortShipments = selectedSort => (a, b) => {
  const today = format(new Date(), "yyyy-MM-dd");
  if (selectedSort === "Students Who Have Waited Longest") {
    if (a?.requestedShippingDate >= today && b?.requestedShippingDate >= today) {
      // Sort by ship date
      if (a?.requestedShippingDate === b?.requestedShippingDate) {
        return a?.teacher?.lastName > b?.teacher?.lastName ? 1 : -1;
      }
      return a?.requestedShippingDate > b?.requestedShippingDate ? 1 : -1;
    } else if (a?.requestedShippingDate >= today) {
      return 1;
    } else if (b?.requestedShippingDate >= today) {
      return -1;
    } else {
      // Actually sort by students who have waited longest
      if (a?.earliestRegistrationDate === b?.earliestRegistrationDate) {
        return a?.teacher?.lastName > b?.teacher?.lastName ? 1 : -1;
      }
      return a?.earliestRegistrationDate > b?.earliestRegistrationDate ? 1 : -1;
    }
  } else if (selectedSort === "Class Start Date") {
    if (a?.earliestClassStartDate === b?.earliestClassStartDate) {
      return a?.teacher?.lastName > b?.teacher?.lastName ? 1 : -1;
    }
    return a?.class?.startDate > b?.class?.startDate ? 1 : -1;
  } else if (selectedSort === "Requested Ship Date") {
    if (a?.requestedShippingDate === b?.requestedShippingDate) {
      return a?.teacher?.lastName > b?.teacher?.lastName ? 1 : -1;
    }
    return a?.requestedShippingDate > b?.requestedShippingDate ? 1 : -1;
  }
};

const filterOptions = ["All", "For Today", "Past Due", "Late Enrollments (Urgent)", "Late Enrollments (All)"];

const filterShipments = selectedFilter => shipment => {
  if (selectedFilter === "For Today") {
    return shipment?.requestedShippingDate === format(new Date(), "yyyy-MM-dd");
  } else if (selectedFilter === "Past Due") {
    return shipment?.status !== "late" && shipment?.requestedShippingDate < format(new Date(), "yyyy-MM-dd");
  } else if (selectedFilter === "Late Enrollments (Urgent)") {
    return shipment?.urgent;
  } else if (selectedFilter === "Late Enrollments (All)") {
    return shipment?.status === "late";
  } else if (selectedFilter === "All") {
    return true;
  }
};

const ShippingAddressCell = ({ addresses }) => {
  let rawAddress = addresses.find(a => a?.field === "SHIPPING");
  if (!rawAddress) rawAddress = addresses[0];
  const { line1, line2, locality: city, region: state, zipCode } = rawAddress || {};
  const addressLine2 = line2 ? <div>{line2?.toProperCase()}</div> : null;

  return (
    <>
      <div>{line1?.toProperCase()}</div>
      {addressLine2}
      <div>
        {city?.toProperCase()}, {state?.toUpperCase()} {zipCode}
      </div>
    </>
  );
};

const ShipmentRow = ({ shipmentId, statusLabel, requestedShippingDate, teacher, items }) => {
  let { id, profilePicture, fullName, firstName, lastName, addresses = [] } = teacher || {};

  fullName = fullName || `${firstName} ${lastName}`;

  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);

  const { Modal: ChangeShipByDateModal, openModal: openChangeShipByDateModal } = useModal(
    ChangeShipByDateModalBody,
    { 
      teacher, 
      oldShipByDate: requestedShippingDate,
      shipmentId: shipmentId
    },
    { portalElementId: "change-ship-by-date-modal" }
  );

  const [downloading, setDownloading] = useState(false);

  const profilePictureComponent = profilePictureError ? (
    <div className="profile-picture placeholder">
      <FiUser size={36} />
    </div>
  ) : (
    <img className="profile-picture" src={profilePicture} alt="Teacher profile" onError={markProfilePictureError} />
  );

  const formattedShippingDate = requestedShippingDate ? format(parseISO(requestedShippingDate), "MM/dd/yyyy") : "-";

  const goToShipmentDetails = () => {
    ShippingStore?.fetchShipmentDetails(shipmentId);
    navigate(`/shipping/${shipmentId}`);
  };
  const downloadPackingSlip = async () => {
    if (!downloading) {
      setDownloading(true);
      await ShippingStore?.downloadPackingSlipForShipment(shipmentId);
      setDownloading(false);
    }
  };

  const totalItems = items;

  const dowloadingActionStyles = downloading ? { opacity: 0.3, pointerEvents: "none" } : null;
  const downloadActionContent = downloading ? <Spinner action /> : "Download Packing Slip";

  return (
    <tr>
      <td>
        <div className="cell-contents">{statusLabel}</div>
      </td>
      <td>
        <div className="cell-contents">{formattedShippingDate}</div>
      </td>
      <td>
        <div className="cell-contents teacher">
          {profilePictureComponent}
          <Link className="action" to={`/people/${id}`}>
            {fullName}
          </Link>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div>{totalItems}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents"></div>
        <ShippingAddressCell addresses={addresses} />
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToShipmentDetails}>
            View Details
          </div>
          <div className="action" onClick={openChangeShipByDateModal}>
            Change Ship By Date
          </div>
          <div className="action" onClick={downloadPackingSlip} style={dowloadingActionStyles}>
            {downloadActionContent}
          </div>
        </div>
      </td>
      <ChangeShipByDateModal />
    </tr>
  );
};

const Shipping = observer(() => {
  const location = useLocation();
  const { sort, show, search } = useQueryParams();

  const {
    loading: shippingLoading,
    shipments,
    totalLateShipments,
    // totalBackorderedShipments,
    totalReservedShipmentsForToday,
    totalReservedShipmentsInThePast
  } = ShippingStore || {};

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [searchText, setSearchText] = useState("");

  const updateSelectedSort = newSort => {
    navigate(
      location.pathname +
        `?sort=${encodeURIComponent(newSort)}&show=${encodeURIComponent(selectedFilter)}${`&search=${encodeURIComponent(
          searchText
        )}`}`
    );
  };
  useEffect(() => {
    if (sort != null && sort !== selectedSort) setSelectedSort(sort);
  }, [sort, selectedSort]);

  const updateSelectedFilter = newFilter => {
    navigate(
      location.pathname +
        `?sort=${encodeURIComponent(selectedSort)}&show=${encodeURIComponent(newFilter)}${`&search=${encodeURIComponent(
          searchText
        )}`}`
    );
  };
  useEffect(() => {
    if (show != null && show !== selectedFilter) setSelectedFilter(show);
  }, [show, selectedFilter]);

  const updateSearchText = ({ target }) => {
    navigate(
      location.pathname +
        `?sort=${encodeURIComponent(selectedSort)}&show=${encodeURIComponent(
          selectedFilter
        )}${`&search=${encodeURIComponent(target?.value)}`}`
    );
  };
  useEffect(() => {
    if (search != null && search !== searchText) setSearchText(search);
  }, [search, searchText]);

  // const [downloadingTodaysShipments, setDownloadingTodaysShipments] = useState(false);
  // const [downloadingLateShipments, setDownloadingLateShipments] = useState(false);

  // const printShipmentsForToday = async () => {
  //   if (!downloadingTodaysShipments) {
  //     setDownloadingTodaysShipments(true);
  //     const shipmentIdsForToday = shipments?.filter(filterShipments("For Today"))?.sort(sortShipments(selectedSort))?.map(s => s?.id);
  //     await ShippingStore.downloadPackingSlipForShipments(shipmentIdsForToday);
  //     setDownloadingTodaysShipments(false);
  //   }
  // };

  // const printLateShipments = async () => {
  //   if (!downloadingLateShipments) {
  //     setDownloadingLateShipments(true);
  //     const lateShipmentIds = shipments?.filter(filterShipments("Late Enrollments"))?.sort(sortShipments(selectedSort))?.map(s => s?.id);
  //     await ShippingStore.downloadPackingSlipForShipments(lateShipmentIds);
  //     setDownloadingLateShipments(false);
  //   }
  // };

  if (shippingLoading) {
    return (
      <div className="shipping shipping-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  // const printTodaysShipmentsActionLabel = downloadingTodaysShipments ? (
  //   <Spinner action style={{ opacity: 0.3 }} />
  // ) : (
  //   "Print Today's Shipments"
  // );

  // const printLateShipmentsActionLabel = downloadingLateShipments ? (
  //   <Spinner action style={{ opacity: 0.3 }} />
  // ) : (
  //   "Print Late Shipments"
  // );

  const applicableShipments = shipments
    ?.filter(filterShipments(selectedFilter))
    ?.filter(s => {
      if (!searchText) return true;
      const textToMatch = searchText?.toLowerCase();
      return s?.teacher?.fullName?.toLowerCase()?.includes(textToMatch); // TODO: || p?.longDescription?.includes(textToMatch);
    })
    ?.sort(sortShipments(selectedSort));
  const shipmentRows = applicableShipments?.map(shipment => <ShipmentRow {...shipment} key={shipment?.shipmentId} />);
  const shipmentsTable = applicableShipments?.length ? (
    <div className="table-wrapper order-items-table">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Ship By Date</th>
            <th>Teacher</th>
            <th># of Items</th>
            <th>Destination Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{shipmentRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no shipments for the moment)</div>
    </div>
  );
  const shipmentsTableContent = shipments?.length ? (
    <>
      <div className="sort-search-row">
        <div className="sort-shipments">
          <span className="sort-shipments-label">Sort:</span>
          <Select options={sortOptions} value={selectedSort} onChange={updateSelectedSort} />
        </div>
        <div className="filter-shipments">
          <span className="filter-shipments-label">Filter:</span>
          <Select options={filterOptions} value={selectedFilter} onChange={updateSelectedFilter} />
        </div>
        <div className="search-shipments">
          <Input icon={FiSearch} placeholder="Search shipments..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {shipmentsTable}
    </>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">You're all caught up - nice work!</div>
    </div>
  );

  return (
    <div className="shipping">
      <div className="title-row">
        <div className="title">Shipping</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Product Demand</div>
          <div className="info-row">
            <div className="info-title">Reserved Items (today)</div>
            <div className="info-value">{totalReservedShipmentsForToday}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Reserved Items (past due)</div>
            <div className="info-value">{totalReservedShipmentsInThePast}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Late Enrollment Items</div>
            <div className="info-value">{totalLateShipments}</div>
          </div>
          {/* <div className="info-row">
            <div className="info-title">Backordered Items</div>
            <div className="info-value">{totalBackorderedShipments}</div>
          </div> */}
        </div>
        <div className="actions-section">
          {/* <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={printShipmentsForToday}>
              <span>{printTodaysShipmentsActionLabel}</span>
            </div>
          </div> */}
          {/* <div className="action-row">
            <div className="action" onClick={printLateShipments}>
              <span>{printLateShipmentsActionLabel}</span>
            </div>
          </div> */}
          {/* <div className="action-row">
            <div className="action">
              <Link to="/shipping/upcoming">
                <span>See Upcoming Schedule</span>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      <div className="header">Products</div>
      {shipmentsTableContent}
    </div>
  );
});

const ShippingRouter = () => {
  return (
    <Router>
      <Shipping path="/" />
      <UpcomingShipmentSchedule path="upcoming" />
      <ShipmentOverview path=":shipmentId" />
      <Redirect from="*" to="/" noThrow />
    </Router>
  );
};

export default ShippingRouter;
